import { createEffect, createResource, createSignal, For } from "solid-js";
import { HowToUseSteps } from "~/server/types/brand";
import { DividerV2 } from "~/widgets/divider";
import { getBrandHowToUseSteps } from "~/server/apis/client_apis";
import { CloseModalButton, ModalInnerFrame } from "~/components/modal";
import { Spacer } from "~/widgets/spacer";

type HowToUseContentProps =
  | {
      brandId: string;
    }
  | {
      howToUseStepsList: HowToUseSteps[];
    };

export function HowToUseContent(props: HowToUseContentProps) {
  const [howToUseStepsList] = createResource<HowToUseSteps[]>(async () => {
    if ("brandId" in props && props.brandId) {
      const howToUseResponse = await getBrandHowToUseSteps(props.brandId);
      return howToUseResponse.howToUseSteps;
    }

    if ("howToUseStepsList" in props && props.howToUseStepsList) {
      return Promise.resolve(props.howToUseStepsList);
    }

    return Promise.resolve([]);
  });

  const [selectedTabIndex, setSelectedTabIndex] = createSignal<number>(0);

  const [steps, setSteps] = createSignal<string[]>(
    "howToUseStepsList" in props && props.howToUseStepsList
      ? props.howToUseStepsList[0].howToUseHints.hints
      : ([] as string[])
  );

  createEffect(() => {
    if (howToUseStepsList()?.length) {
      setSteps(howToUseStepsList()![selectedTabIndex()].howToUseHints.hints);
    }
  });

  return (
    <ModalInnerFrame>
      <div class="flex h-fit flex-col items-start justify-between self-stretch">
        <div class="flex w-full flex-row  items-start justify-between ">
          <div class="font-['Inter']  text-h3  text-textDark dark:text-textWhite ">
            How to use / redeem
          </div>
          <CloseModalButton />
        </div>

        <ul class="mt-3 flex list-none flex-row gap-4 text-mediumBold text-textDark">
          <For each={howToUseStepsList.latest}>
            {(howToUseSteps, index) => {
              return (
                <li
                  class="cursor-pointer pb-[6px] transition-all duration-300 dark:text-textWhite"
                  classList={{
                    "border-b-2 border-solid border-[#212427] dark:border-[#FFF]":
                      selectedTabIndex() == index(),
                  }}
                  style="border-bottom-radius: 2px"
                  onClick={() => {
                    setSelectedTabIndex(index());
                  }}
                >
                  {howToUseSteps.retailModeName}
                </li>
              );
            }}
          </For>
        </ul>
        <div class="flex h-fit w-full flex-row">
          <DividerV2 class="bg-baseTertiaryMedium dark:bg-basePrimaryMedium" />
        </div>
        <Spacer height={16} />
      </div>
      <div class="flex h-fit flex-col items-start justify-start gap-2 self-stretch">
        <For each={steps()}>
          {(howToUseHint, index) => (
            <div class="inline-flex items-start justify-start gap-2 self-stretch">
              <div class="relative mt-[2px] h-5 w-5 rounded-[15px] bg-gray-100 dark:bg-basePrimaryMedium">
                <div class="absolute left-0 top-0 h-5 w-5">
                  <div class="absolute left-[7px] top-[3px] text-[10px] font-bold leading-[14px] text-textDark dark:text-textWhite">
                    {index() + 1}
                  </div>
                </div>
              </div>
              <div class="shrink grow basis-0">
                <span class="text-normal text-textDark dark:text-textWhite">
                  {howToUseHint}
                </span>
              </div>
            </div>
          )}
        </For>
      </div>
    </ModalInnerFrame>
  );
}
